import * as React from "react";
import { AccessibleSpan } from "../accessibility/accessible_components";
import HintComponent from "../hint_component";

interface IProps {
    label: string;
    checked: boolean;
    hint?: string;
    onChange: () => void;
    name: string;
}

export default function SwitchComponent({
    label,
    checked,
    onChange,
    hint,
    name,
}: IProps) {
    return (
        <label className="switch">
            <label>
                <span>{label}</span>
                {hint && <HintComponent>{hint}</HintComponent>}
            </label>
            <input type="checkbox" checked={checked} tabIndex={-1} readOnly />
            <AccessibleSpan
                className="slider"
                onClick={onChange}
                data-cy={name}
            ></AccessibleSpan>
        </label>
    );
}
