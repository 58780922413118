import classNames from "classnames";
import * as React from "react";
import { useFocusVisible } from "use-focus-visible";
import HintComponent from "../hint_component";

export interface IOption {
    key: string;
    value: string;
}

interface ISelectProps {
    label: string;
    value: string;
    options: IOption[];
    disabled?: boolean;
    name: string;
    className?: string;
    onChange: (option: IOption) => void;
    hint?: string;
    placeholder?: string;
}

export default function SelectComponent(props: ISelectProps) {
    const {
        label,
        name,
        value,
        options,
        hint,
        className,
        onChange,
        placeholder,
    } = props;

    const { focusVisible, onFocus, onBlur } = useFocusVisible();

    function emitChange(event) {
        if (!onChange) {
            return;
        }

        const value = event.target.value;
        if (value === null) {
            onChange(null);
        }

        const option = options.find((option) => option.key === value);
        onChange(option);
    }

    return (
        <div className="select-component">
            <label>
                <span>{label}</span>
                {hint && <HintComponent>{hint}</HintComponent>}
            </label>
            <div className="select-wrapper">
                <i className="icon icon-arrow-down"></i>
                <select
                    onChange={emitChange}
                    name={name}
                    value={value}
                    className={classNames(className, {
                        "focus-visible": focusVisible,
                    })}
                    data-cy={name}
                    onFocus={onFocus}
                    onBlur={onBlur}
                >
                    {placeholder && (
                        <option value="" disabled>
                            {placeholder}
                        </option>
                    )}
                    {options.map((option) => {
                        return (
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
