import * as React from "react";
import { useTranslation } from "react-i18next";
import { toCurrency } from "../../utils/format";
import PaymentAmountField from "./fields/payment_amount_field";
import PaymentDateField from "./fields/payment_date_field";
import AccountAmountField from "./fields/account_amount_field";
import { getRequiredMonthlySavings } from "../../selectors/savings";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers/root_reducer";
import { showSavingsCalculator } from "../../selectors/housings";

export default function SavingsCalculatorComponent() {
    const { t } = useTranslation();

    const { renderSavingsCalculator, montlySavings } = useSelector(
        (state: IRootState) => {
            return {
                renderSavingsCalculator: showSavingsCalculator(state.prospect),
                montlySavings: getRequiredMonthlySavings(state),
            };
        }
    );

    if (!renderSavingsCalculator) return <></>;

    return (
        <section data-cy="savings-calculator-section">
            <div className="header">
                <div>
                    <h3>{t("savings:header")}</h3>
                </div>
                <div className="row">
                    <div>
                        <h4>{t("savings:total")}</h4>
                        <h4>
                            <strong data-cy="monthly-savings">
                                {toCurrency(montlySavings)}
                            </strong>
                        </h4>
                    </div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className="body">
                <div className="row">
                    <div className="container">
                        <PaymentDateField />
                    </div>
                    <div className="container">
                        <PaymentAmountField />
                    </div>
                    <div className="container">
                        <AccountAmountField />
                    </div>
                </div>
            </div>
        </section>
    );
}
