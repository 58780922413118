import * as React from "react";

import { toCurrency } from "../utils/format";
import { calculateDeed, calculateMortgageDeedFee } from "../functions/housing";
import HintComponent from "./hint_component";

import { useTranslation } from "react-i18next";
import { HousingTypes, IHousing } from "../models/housing";
import { getCapitalGainTax, getCapital } from "../selectors/housings";
import { monthlyInterestCostAfterDeduction } from "../functions/loans";
import {
    getTotalLoanAmount,
    getTotalLoanInstallment,
} from "../selectors/loans";

interface ITotalCostBreakdownProps {
    housing: IHousing;
}

export function TotalCostBreakdownComponent(props: ITotalCostBreakdownProps) {
    const { t } = useTranslation();
    const { housing } = props;
    const { fee, loans, maintenance } = housing;

    const interest = monthlyInterestCostAfterDeduction(loans);
    const installment = getTotalLoanInstallment(housing);

    const isRental = housing.type === HousingTypes.Rental;
    const isHouse = housing.type === HousingTypes.House;

    return (
        <ul className="cost-breakdown">
            {!isRental && (
                <>
                    <li>
                        <span>
                            {t("common:interest")}
                            <i>{t("common:interest-tax")}</i>
                        </span>
                        <span className="sum" data-cy="interest-cost">
                            {toCurrency(interest)}
                        </span>
                        <span className="cost-hint">
                            <HintComponent>{t("hint:interest")}</HintComponent>
                        </span>
                    </li>
                    <li>
                        <span>{t("common:amortization")}</span>
                        <span className="sum">{toCurrency(installment)}</span>
                        <span className="cost-hint"></span>
                    </li>
                </>
            )}
            <li>
                <span>{t("common:maintenance")}</span>
                <span className="sum" data-cy="maintenance">
                    {Number.isInteger(maintenance) ? (
                        toCurrency(maintenance)
                    ) : (
                        <i>{t("common:no-information")}</i>
                    )}
                </span>
                <span className="cost-hint"></span>
            </li>
            {!isHouse && (
                <li>
                    <span>{t("common:fee")}</span>
                    <span className="sum" data-cy="fee">
                        {Number.isInteger(fee) ? (
                            toCurrency(fee)
                        ) : (
                            <i>{t("common:no-information")}</i>
                        )}
                    </span>
                    <span className="cost-hint"></span>
                </li>
            )}
        </ul>
    );
}

interface IMiscCostBreakdownProps {
    housing: IHousing;
}

export function MiscCostBreakdownComponent(props: IMiscCostBreakdownProps) {
    const { t } = useTranslation();
    const { housing } = props;
    const { price, type, mortgage } = housing;

    if (type !== HousingTypes.House) return null;

    const deed = calculateDeed(type, price);
    const loans = getTotalLoanAmount(housing);
    const deedFee = calculateMortgageDeedFee(loans, mortgage);

    return (
        <ul className="cost-breakdown">
            <li>
                <span>{t("common:deed")}</span>
                <span className="sum" data-cy="deed">
                    {Number.isInteger(deed) && deed !== 0 ? (
                        toCurrency(deed)
                    ) : (
                        <i>{t("common:no-information")}</i>
                    )}
                </span>
                <span className="cost-hint">
                    <HintComponent>{t("hint:deed")}</HintComponent>
                </span>
            </li>
            <li>
                <span>{t("common:deed-fee")}</span>
                <span className="sum" data-cy="deed-fee">
                    {Number.isInteger(deedFee) ? (
                        toCurrency(deedFee)
                    ) : (
                        <i>{t("common:no-information")}</i>
                    )}
                </span>
                <span className="cost-hint">
                    <HintComponent>{t("hint:deed-fee")}</HintComponent>
                </span>
            </li>
        </ul>
    );
}

interface ICapitalBreakdownProps {
    housing: IHousing;
}

export function CapitalBreakdownComponent(props: ICapitalBreakdownProps) {
    const { t } = useTranslation();
    const { housing } = props;
    const { type, calculateCapitalGainTax = false } = housing;

    if (type === HousingTypes.Rental) return null;

    let capital = getCapital(housing);
    const capitalGainTax = getCapitalGainTax(housing);
    if (calculateCapitalGainTax) capital = capital - capitalGainTax;

    return (
        <ul className="cost-breakdown">
            <li>
                <span>
                    {t("existing:capital")}{" "}
                    {calculateCapitalGainTax && (
                        <i>{t("existing:capital-tax")}</i>
                    )}
                </span>
                <span className="sum" data-cy="capital">
                    {Number.isInteger(capital) && capital !== 0 ? (
                        toCurrency(capital)
                    ) : (
                        <i>{t("common:no-information")}</i>
                    )}
                </span>
                <span className="cost-hint"></span>
            </li>
            {calculateCapitalGainTax && (
                <li>
                    <span>{t("existing:capital-gain-tax")}</span>
                    <span className="sum" data-cy="capital-gain-tax">
                        {Number.isInteger(capitalGainTax) ? (
                            toCurrency(capitalGainTax)
                        ) : (
                            <i>{t("common:no-information")}</i>
                        )}
                    </span>
                    <span className="cost-hint"></span>
                </li>
            )}
        </ul>
    );
}
