import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compareTotalHousingCost } from "../../functions/housing";
import { IRootState } from "../../reducers/root_reducer";
import { toCurrency } from "../../utils/format";

export default function MonthlyCostComparison() {
    const { t } = useTranslation();
    const { difference } = useSelector((state: IRootState) => {
        return {
            difference: compareTotalHousingCost(state.prospect, state.existing),
        };
    });

    const direction =
        difference >= 0 ? t("compare:increase") : t("compare:decrease");

    const comparison = t("compare:result-comparison", {
        direction,
        difference: `<strong data-cy='compare-monthly-cost'>${
            difference > 0 ? "+" : "&#150"
        } ${toCurrency(Math.abs(difference))}</strong>`,
    });

    return (
        <>
            <h5>{t("compare:monthly-cost-header")}</h5>
            <p
                dangerouslySetInnerHTML={{
                    __html: comparison,
                }}
            ></p>
        </>
    );
}
