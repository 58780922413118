import {
    HIGHEST_LOAN_TO_VALUE_THRESHOLD,
    HIGH_LOAN_TO_VALUE_FACTOR,
    HIGH_LOAN_TO_VALUE_THRESHOLD,
    LOAN_TO_INCOME_THRESHOLD,
    LOW_LOAN_TO_INCOME_FACTOR,
    LOW_LOAN_TO_VALUE_INSTALLMENT_FACTOR,
    LOW_LOAN_TO_VALUE_THRESHOLD,
    LTV_THRESHOLDS,
    MONTHS_IN_YEAR,
} from "../defaults";

export function minInstallment(
    price: number,
    loan: number,
    income: number
): number {
    return Math.round((loan * minRate(price, loan, income)) / MONTHS_IN_YEAR);
}

export function calculateDownpayment(price: number, loanToValueFactor: number) {
    return price * (1 - loanToValueFactor);
}

export function installmentThresholds(price: number, income: number): number[] {
    const LOAN_TO_VALUE_THRESHOLD_BY_INCOME_CUTOFF =
        loanToValueThresholdToAvoidIncomeInstallmentRate(price, income);
    return Array.from(
        new Set([LOAN_TO_VALUE_THRESHOLD_BY_INCOME_CUTOFF, ...LTV_THRESHOLDS])
    )
        .filter((threshold) => threshold <= HIGHEST_LOAN_TO_VALUE_THRESHOLD)
        .sort()
        .reverse();
}

function loanToValueThresholdToAvoidIncomeInstallmentRate(
    price: number,
    income: number
): number {
    const yearlyIncome = income * MONTHS_IN_YEAR;
    const maxLoanToAvoidInstallmentRate =
        yearlyIncome * LOAN_TO_INCOME_THRESHOLD;
    return maxLoanToAvoidInstallmentRate / price;
}

export function requiredMinInstallmentRate(
    price: number,
    loanToValueFactor: number,
    income: number
): number {
    const loan = calculateLoan(price, loanToValueFactor);
    return minRate(price, loan, income);
}

export function requiredMinInstallment(
    price: number,
    loanToValueFactor: number,
    income: number
): number {
    const loan = calculateLoan(price, loanToValueFactor);
    return minInstallment(price, loan, income);
}

export function loanToIncomeRatio(loan: number, yearlyIncome: number): number {
    return loan / yearlyIncome;
}

function minRate(price: number, loan: number, income: number): number {
    return capitalRate(price, loan) + incomeRate(loan, income);
}

function loanToValueRatio(price: number, loan: number): number {
    return loan / price;
}

function capitalRate(price: number, loan: number): number {
    const ratio = loanToValueRatio(price, loan);

    if (ratio > HIGH_LOAN_TO_VALUE_THRESHOLD) return HIGH_LOAN_TO_VALUE_FACTOR;

    if (ratio > LOW_LOAN_TO_VALUE_THRESHOLD)
        return LOW_LOAN_TO_VALUE_INSTALLMENT_FACTOR;

    return 0;
}

function incomeRate(loan: number, income: number): number {
    const yearlyIncome = income * MONTHS_IN_YEAR;

    const ratio = loanToIncomeRatio(loan, yearlyIncome);

    if (ratio <= LOAN_TO_INCOME_THRESHOLD) return 0;

    return LOW_LOAN_TO_INCOME_FACTOR;
}

function calculateLoan(price: number, loanToValueFactor: number): number {
    // Without rounding, the loan would become slightly too big (maybe 10^-6) so
    // that the installment thresholds didn't match the expected rate.
    return Math.round(price * loanToValueFactor);
}
