import * as React from "react";
import track from "react-tracking";
import config from "react-global-configuration";
import { FocusVisibleManager } from "use-focus-visible";

import Prospect from "./prospect/prospect_component";
import Existing from "./existing/existing_component";
import SavingsCalculator from "./savings/savings_calculator_component";
import { EventCategories, WidgetEventAction } from "../utils/analytics";
import type { TrackingData } from "@econans/tracking";

@track(
    { page: "MoveCalculator" },
    {
        dispatchOnMount: (): TrackingData => ({
            action: WidgetEventAction.Widget_Loaded,
            tag: EventCategories.Widget,
            label: EventCategories.Widget,
        }),
        dispatch: (data) => config.get("onEvent")(data),
    }
)
export default class MoveCalculatorComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FocusVisibleManager>
                <div className="widget-container">
                    <Prospect />

                    <Existing />

                    <SavingsCalculator />
                </div>
            </FocusVisibleManager>
        );
    }
}
