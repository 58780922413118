import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AccessibleDiv,
    AccessibleLi,
} from "./accessibility/accessible_components";

export interface ICarouselItem {
    id: string;
    label: string;
    content: React.ReactNode;
}

export interface ICarouselProps {
    items: ICarouselItem[];
}

export enum SlideDirection {
    Left = "left",
    Right = "right",
    None = "",
}

export default function CarouselComponent(props: ICarouselProps) {
    const { t } = useTranslation();
    const { items } = props;
    const [index, setIndex] = useState(0);
    const [slide, setSlide] = useState(false);
    const [direction, setDirection] = useState(SlideDirection.None);
    const item = items[index];
    const { content } = item;
    const end = items.length - 1;

    const toggleClass = () => {
        setSlide(true);
    };

    const animateSlide = (dir: SlideDirection, newIndex?: number) => {
        setDirection(dir);
        toggleClass();
        setTimeout(() => setSlide(false), 350);

        if (index < newIndex) {
            setDirection(SlideDirection.Right);
        }
        if (index > newIndex) {
            setDirection(SlideDirection.Left);
        }
        if (index === newIndex) {
            setDirection(SlideDirection.None);
        }
    };

    const onArrowClick = (dir: SlideDirection, newIndex: number) => {
        setIndex(newIndex);
        animateSlide(dir);
    };

    const onIndicatorClick = (newIndex: number) => {
        setIndex(newIndex);
        animateSlide(SlideDirection.None, newIndex);
    };

    return (
        <div className="carousel" role="tablist">
            <div
                className={classNames("content", {
                    "slide-right": slide && direction === SlideDirection.Right,
                    "slide-left": slide && direction === SlideDirection.Left,
                })}
                role="tabpanel"
            >
                {content}
            </div>
            <AccessibleDiv
                className="left"
                onClick={() =>
                    onArrowClick(
                        SlideDirection.Left,
                        index > 0 ? index - 1 : end
                    )
                }
                role="button"
                aria-label={t("offer:left")}
            >
                <i className="icon icon-arrow-left" />
            </AccessibleDiv>
            <AccessibleDiv
                className="right"
                onClick={() =>
                    onArrowClick(
                        SlideDirection.Right,
                        index < end ? index + 1 : 0
                    )
                }
                role="button"
                aria-label={t("offer:right")}
            >
                <i className="icon icon-arrow-right" />
            </AccessibleDiv>
            <div className="indicators">
                <ul>
                    {items.map((i: ICarouselItem, index: number) => {
                        return (
                            <AccessibleLi
                                key={index}
                                className={classNames({
                                    active: item.id === i.id,
                                })}
                                onClick={() => onIndicatorClick(index)}
                                role="tab"
                            >
                                {i.label}
                            </AccessibleLi>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
