import * as React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers/root_reducer";
import { showTimeline } from "../../selectors/housings";
import { getPaymentSteps } from "../../selectors/savings";
import { capitalize, formatDate, toCurrency } from "../../utils/format";
import HintComponent from "../hint_component";

export default function TimelineComponent() {
    const { t } = useTranslation();
    const { renderTimeline, name, paymentSteps } = useSelector(
        (state: IRootState) => {
            return {
                renderTimeline: showTimeline(state.prospect),
                name: state.prospect.name,
                paymentSteps: getPaymentSteps(state),
            };
        }
    );

    if (!renderTimeline) return <></>;

    return (
        <React.Fragment>
            <div>
                <h4>
                    {t("timeline:header", { name })}{" "}
                    <HintComponent>{t("hint:timeline")}</HintComponent>
                </h4>
            </div>
            <div className="row timeline">
                {paymentSteps.map((step, index) => {
                    const isLastStep = index === paymentSteps.length - 1;
                    return (
                        <div
                            className="container fill shrink"
                            key={index}
                            data-cy={step.id}
                        >
                            <div>
                                <p>
                                    <b>{capitalize(formatDate(step.date))}</b>
                                </p>
                                <p>{t(`common:${step.id}`)}</p>
                                <p>
                                    <strong>{toCurrency(step.amount)}</strong>
                                </p>
                                <p>
                                    {t("timeline:disclaimer", {
                                        percentage: step.percentage,
                                    })}
                                </p>
                            </div>
                            <div>
                                {!isLastStep && (
                                    <i className="icon icon-arrow-full"></i>
                                )}
                            </div>
                        </div>
                    );
                })}
                {
                    paymentSteps.length === 2 && (
                        <div></div>
                    ) /* TODO: Remove this ugly hack that compensates for justify-content: space-between on .row */
                }
            </div>
        </React.Fragment>
    );
}
