import * as React from "react";
import "moment/locale/sv";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    IRootState,
    setSavingsPaymentDate,
} from "../../../reducers/root_reducer";
import Select, { IOption } from "../../form_controls/select_component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import { getPaymentSteps } from "../../../selectors/savings";

export default function PaymentDateField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { paymentDate, paymentSteps, type } = useSelector(
        (state: IRootState) => {
            return {
                paymentDate: state.savings.paymentDate,
                paymentSteps: getPaymentSteps(state),
                type: state.prospect.type,
            };
        }
    );

    const options = paymentSteps.map(
        (x) =>
            ({
                key: x.id,
                value: `${t(`common:${x.id}`)} - ${x.date.format("MMMM YYYY")}`,
            } as IOption)
    );

    function onChange(option: IOption) {
        const step = paymentSteps.find((x) => x.id === option.key);
        dispatch(setSavingsPaymentDate(step));

        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Amount_To_Pay,
                housingType: type,
                value,
            })
        );
    }

    const value = paymentDate
        ? paymentSteps.find((x) => x.date.isSame(paymentDate)).id
        : "";

    return (
        <Select
            label={t("savings:payment-date")}
            value={value}
            name="savings-payment-date"
            onChange={onChange}
            options={options}
            placeholder={t("common:select-option")}
        />
    );
}
