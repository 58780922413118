import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HousingTypes } from "../../models/housing";
import { IRootState, setExistingType } from "../../reducers/root_reducer";
import Tabs, { Tab } from "../tabs_component";
import RentalFields from "./types/rental_fields_component";
import CondominiumFields from "./types/condominium_fields_component";
import HouseFields from "./types/house_fields_component";

export default function ExistingFieldsComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { type } = useSelector((state: IRootState) => {
        return {
            type: state.existing.type,
        };
    });

    function onSetExistingType(type: HousingTypes) {
        dispatch(setExistingType(type));
    }

    return (
        <Tabs onClick={onSetExistingType} value={type}>
            <Tab
                title={t(`common:${HousingTypes.Rental}`)}
                value={HousingTypes.Rental}
                name={`select-${HousingTypes.Rental}`}
            >
                <RentalFields />
            </Tab>
            <Tab
                title={t(`common:${HousingTypes.Condominium}`)}
                value={HousingTypes.Condominium}
                name={`select-${HousingTypes.Condominium}`}
            >
                <CondominiumFields />
            </Tab>
            <Tab
                title={t(`common:${HousingTypes.House}`)}
                value={HousingTypes.House}
                name={`select-${HousingTypes.House}`}
            >
                <HouseFields />
            </Tab>
        </Tabs>
    );
}
