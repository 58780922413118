import * as React from "react";

import { toCurrency } from "../../utils/format";
import {
    TotalCostBreakdownComponent,
    CapitalBreakdownComponent,
} from "../cost_breakdown_component";
import { useDispatch, useSelector } from "react-redux";
import { IRootState, setExistingType } from "../../reducers/root_reducer";
import { useTranslation } from "react-i18next";
import { getTotalHousingCost } from "../../selectors/housings";
import ExistingFields from "./existing_fields_component";
import classNames from "classnames";
import Compare from "./compare_component";
import { HousingTypes } from "../../models/housing";
import config from "react-global-configuration";
import { AccessibleButton } from "../accessibility/accessible_components";

export default function ExistingComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const renderExistingHousing = config.get<boolean>("existing", true);
    if (!renderExistingHousing) return <></>;

    const { existing, totalCost } = useSelector((state: IRootState) => {
        return {
            existing: state.existing,
            totalCost: state.existing ? getTotalHousingCost(state.existing) : 0,
        };
    });

    function onSetExistingType(type: HousingTypes) {
        dispatch(setExistingType(type));
    }

    const headerClassNames = classNames("header", { collapsed: !existing });

    return (
        <section data-cy="existing-housing-section">
            <div className={headerClassNames}>
                <div>
                    <h3>{t("existing:header")}</h3>
                </div>
                <div
                    className={classNames({
                        row: existing,
                    })}
                >
                    <div>
                        {existing ? (
                            <>
                                <h4>{t("existing:total")}</h4>
                                <h4>
                                    <strong data-cy="existing-monthly-cost">
                                        {toCurrency(totalCost)}
                                    </strong>
                                </h4>
                            </>
                        ) : (
                            <h4>{t("existing:select-type")}</h4>
                        )}
                    </div>
                    {existing ? (
                        <>
                            <div>
                                <TotalCostBreakdownComponent
                                    housing={existing}
                                />
                            </div>
                            <div>
                                <CapitalBreakdownComponent housing={existing} />
                            </div>
                        </>
                    ) : (
                        <div className="type-of-residence">
                            {[
                                HousingTypes.Rental,
                                HousingTypes.Condominium,
                                HousingTypes.House,
                            ].map((type) => {
                                return (
                                    <AccessibleButton
                                        key={type}
                                        data-cy={`select-${type}`}
                                        className="secondary"
                                        onClick={() => onSetExistingType(type)}
                                    >
                                        {t(`common:${type}`)}
                                    </AccessibleButton>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            {existing && (
                <div className="body">
                    <ExistingFields />
                    <Compare />
                </div>
            )}
        </section>
    );
}
