import * as React from "react";

import { useTranslation } from "react-i18next";
import Number from "../form_controls/number_component";
import { LOAN_INSTALLMENT_STEP } from "../../defaults";
import { getInstallmentRate, getMinInstallment } from "../../selectors/loans";
import { userFormattedPercent } from "../../utils/format";
import { ILoanFieldProp } from "./loan_field_base";

export interface ILoanInstallmentProps extends ILoanFieldProp {
    income: number;
    showRatio?: boolean;
    useAmortizationRequirement?: boolean;
}

export default function LoanInstallmentField(props: ILoanInstallmentProps) {
    const { t } = useTranslation();
    const {
        housing,
        loan,
        income,
        showRatio = true,
        useAmortizationRequirement,
        onChange,
        onValueSet,
    } = props;

    const { installment } = loan;

    const ratio = getInstallmentRate(housing);
    const minValue = getMinInstallment(income, housing);

    const maxValue = Math.max(10000, minValue * 2);

    const amortizationMin = useAmortizationRequirement ? minValue : 0;

    return (
        <Number
            label={t("loan:installment")}
            unit="kr"
            ariaLabel={t("common:currency")}
            preInputValue={
                showRatio ? `${userFormattedPercent(ratio, 0)} %` : null
            }
            value={installment}
            hint={t("hint:loan-installment")}
            min={amortizationMin}
            max={maxValue}
            step={LOAN_INSTALLMENT_STEP}
            name="loan-installment"
            preserveMin={true}
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
