import config from "react-global-configuration";

import { IHousing, HousingTypes } from "../models/housing";
import {
    calculateCapitalGainTax,
    calculateHousingCapital,
    calculateTotalHousingCost,
} from "../functions/housing";
import {
    HIGHEST_LOAN_TO_VALUE_THRESHOLD,
    MIN_CAPITAL_RATIOS,
} from "../defaults";
import { IHousingInformation } from "../models/config";
import { getLoanToValue, getTotalLoanAmount } from "./loans";

export function getCapitalToValueRatio(housing: IHousing) {
    const capital = getCapital(housing);

    return capital / housing.price;
}

export function getTotalHousingCost(housing: IHousing): number {
    return calculateTotalHousingCost(housing);
}

export function getCapitalGainTax(housing: IHousing): number {
    return calculateCapitalGainTax(housing);
}

export function getCapital(housing: IHousing) {
    return calculateHousingCapital(housing);
}

export function getMinimumCapital(housing: IHousing) {
    return Math.round(MIN_CAPITAL_RATIOS.Prospect * housing.price);
}

export function isLoanToValueHigh(housing: IHousing): boolean {
    const ratio = getLoanToValue(housing);
    return ratio > HIGHEST_LOAN_TO_VALUE_THRESHOLD;
}

export function getHousingInformation(housing: IHousing): IHousingInformation {
    return {
        capital: calculateHousingCapital(housing),
        loan: getTotalLoanAmount(housing),
        type: housing.type,
        price: housing.price,
        fee: housing.fee,
        maintenance: housing.maintenance,
    };
}

export function getOriginalPriceMax(housing: IHousing): number {
    return housing.price * 1.5;
}

export function isRental(housing: IHousing): boolean {
    return housing && housing.type === HousingTypes.Rental;
}

export function getMinPrice(housing: IHousing): number {
    const { originalPrice = 0 } = housing;

    return originalPrice / 2;
}

export function getMaxPrice(housing: IHousing) {
    const { originalPrice } = housing;

    // If price is below 1 000 kr it's probably because there's no price
    // available (object is getting ready to be sold). To avoid unrealistically
    // low max values we fallback to 10 000 000 kr in this case.
    return originalPrice < 100000 ? 10000000 : originalPrice * 2;
}

export function showTimeline(housing: IHousing) {
    const feature = config.get<boolean>("timeline", false);

    return feature && housing.occupancyDate != null;
}

export function showSavingsCalculator(housing: IHousing) {
    const feature = config.get<boolean>("savingsCalculator", false);

    return feature && housing.occupancyDate != null;
}
