import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState, setProspectCapital } from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import {
    MiniCalculatorInput,
    trackEngagement,
    trackMiniCalculator,
    UserInput,
} from "../../../utils/analytics";
import { CAPITAL_STEP } from "../../../defaults";
import {
    getCapital,
    getCapitalToValueRatio,
    getMinimumCapital,
} from "../../../selectors/housings";
import { userFormattedPercent } from "../../../utils/format";
import classNames from "classnames";

export default function CapitalField({ mini = false }) {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { capital, ratio, price, minimumCapital, type } = useSelector(
        (state: IRootState) => {
            return {
                capital: getCapital(state.prospect),
                ratio: getCapitalToValueRatio(state.prospect),
                price: state.prospect.price,
                minimumCapital: getMinimumCapital(state.prospect),
                type: state.prospect.type,
            };
        }
    );

    function onChange(value: number) {
        dispatch(setProspectCapital(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Housing_Capital,
                value: value.toString(),
                housingType: type,
            })
        );

        if (!mini) return;

        tracking.trackEvent(
            trackMiniCalculator({
                action: MiniCalculatorInput.Housing_Capital,
                label: MiniCalculatorInput.Housing_Capital,
                value: value.toString(),
            })
        );
    }

    return (
        <Number
            label={t("prospect:capital")}
            unit="kr"
            ariaLabel={t("common:currency")}
            className={classNames({ alert: capital < 0 })}
            preInputValue={`${userFormattedPercent(ratio, 0)} %`}
            value={capital}
            hint={t("hint:capital")}
            preserveMin={true}
            min={minimumCapital}
            max={price}
            step={CAPITAL_STEP}
            name={mini ? "mini-capital" : "capital"}
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
