import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    IRootState,
    setSavingsPaymentAmount,
} from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import {
    SAVINGS_PAYMENT_AMOUNT_MIN,
    SAVINGS_PAYMENT_AMOUNT_STEP,
} from "../../../defaults";

export default function PaymentAmountField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { price, amount, type } = useSelector((state: IRootState) => {
        return {
            price: state.prospect.price,
            amount: state.savings.paymentAmount,
            type: state.prospect.type,
        };
    });

    function onChange(value: number) {
        dispatch(setSavingsPaymentAmount(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Amount_To_Pay,
                value: value.toString(),
                housingType: type,
            })
        );
    }

    return (
        <Number
            label={t("savings:payment-amount")}
            unit="kr"
            ariaLabel={t("common:currency")}
            value={amount}
            min={SAVINGS_PAYMENT_AMOUNT_MIN}
            max={price}
            step={SAVINGS_PAYMENT_AMOUNT_STEP}
            name="savings-payment-amount"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
