import * as React from "react";

import ProspectFields from "./prospect_fields_component";
import { toCurrency } from "../../utils/format";
import {
    TotalCostBreakdownComponent,
    MiscCostBreakdownComponent,
} from "../cost_breakdown_component";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers/root_reducer";
import { useTranslation } from "react-i18next";
import { getTotalHousingCost } from "../../selectors/housings";
import Insight from "./insight_component";
import Timeline from "./timeline_component";

export default function ProspectComponent() {
    const { t } = useTranslation();

    const { prospect, totalCost } = useSelector((state: IRootState) => {
        return {
            prospect: state.prospect,
            totalCost: getTotalHousingCost(state.prospect),
        };
    });

    return (
        <section data-cy="prospect-housing-section">
            <div className="header">
                <div>
                    <h3>{prospect.name}</h3>
                </div>
                <div className="row">
                    <div>
                        <h4>{t("prospect:total")}</h4>
                        <h4>
                            <strong data-cy="prospect-monthly-cost">
                                {toCurrency(totalCost)}
                            </strong>
                        </h4>
                    </div>
                    <div>
                        <TotalCostBreakdownComponent housing={prospect} />
                    </div>
                    <div>
                        <MiscCostBreakdownComponent housing={prospect} />
                    </div>
                </div>
            </div>
            <div className="body">
                <ProspectFields />
                <Insight />
                <Timeline />
            </div>
        </section>
    );
}
