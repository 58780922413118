import {
    calculateNewLoanAmount,
    updateHousingLoans,
    updateMinInstallment,
} from "../functions/loans";
import { clone, IRootState } from "../reducers/root_reducer";

export function setProspectEstimatedPriceFn(state: IRootState, action) {
    const newState = clone(state);

    const newLoanAmount = calculateNewLoanAmount(
        newState.prospect,
        action.payload
    );
    newState.prospect = updateHousingLoans(newState.prospect, newLoanAmount);

    newState.prospect.price = action.payload;
    newState.prospect = updateMinInstallment(
        newState.prospect,
        newState.income
    );

    return newState;
}

export function setProspectCapitalFn(state: IRootState, action) {
    const newState = clone(state);

    const newLoanAmount = newState.prospect.price - action.payload;
    newState.prospect = updateHousingLoans(newState.prospect, newLoanAmount);

    newState.prospect = updateMinInstallment(
        newState.prospect,
        newState.income
    );

    return newState;
}

export function setProspectLoanAmountFn(state: IRootState, action) {
    const newState = clone(state);

    newState.prospect = updateHousingLoans(newState.prospect, action.payload);
    newState.prospect = updateMinInstallment(
        newState.prospect,
        newState.income
    );

    return newState;
}

export function setProspectLoanInterestFn(state: IRootState, action) {
    const newState = clone(state);

    newState.prospect.loans[0].interest = action.payload;

    return newState;
}

export function setProspectLoanInstallmentFn(state: IRootState, action) {
    const newState = clone(state);

    newState.prospect.loans[0].installment = action.payload;

    return newState;
}
