import common from "./common.json";
import hint from "./hint.json";
import offer from "./offer.json";
import prospect from "./prospect.json";
import loan from "./loan.json";
import insight from "./insight.json";
import existing from "./existing.json";
import compare from "./compare.json";
import savings from "./savings.json";
import mini from "./mini.json";
import timeline from "./timeline.json";

export default {
    common,
    hint,
    offer,
    prospect,
    loan,
    insight,
    existing,
    compare,
    savings,
    mini,
    timeline,
};
