import * as React from "react";

import Offer from "../prospect/offer_component";
import EstimatedPriceField from "./fields/estimated_price_field";
import IncomeField from "./fields/income_field";
import CapitalField from "./fields/capital_field";
import LoanField from "./fields/loan_field";
import BankOffer from "./bank_offer_component";
import { getBankOffer, getOffer } from "../../utils/offer";

export default function ProspectFieldsComponent() {
    const offer = getOffer() || getBankOffer();

    return (
        <div className="row">
            <div className="hint-container container">
                <EstimatedPriceField />
                <IncomeField />
                <CapitalField />
            </div>
            <div
                className="hint-container hint-container--border container outline"
                data-cy="housing-loan"
            >
                <LoanField />
            </div>
            {offer ? (
                <div className="hint-container container fill compact">
                    {getOffer() ? <Offer /> : <BankOffer />}
                </div>
            ) : (
                /* TODO: Remove this ugly hack that compensates for justify-content: space-between on .row */
                <div></div>
            )}
        </div>
    );
}
