import * as React from "react";
import * as ReactDOM from "react-dom";
import { IRootState, rootReducer } from "./reducers/root_reducer";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import config from "react-global-configuration";
import { econansTracking, type TrackingData } from "@econans/tracking";

import initTranslation from "./utils/internationalization";

import MoveCalculator from "./components/move_calculator_component";
import MiniMoveCalculator from "./components/mini_move_calculator_component";

import { IMoveCalculatorParameters } from "./models/config";
import { createHousing } from "./models/housing";
import { createSavings } from "./models/savings";
import { computeHouseholdIncome } from "./functions/housing";
import { createProspectHousingLoan } from "./models/loan";
import requireAll from "./utils/require_all";
import packageJson from "../package.json";

requireAll(require.context("./components/modules/", true, /\.tsx?$/));

function composeEnhancers() {
    if (process.env.NODE_ENV === "production") {
        return compose;
    } else {
        return (
            typeof window === "object" &&
            (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
                ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({})
                : compose)
        );
    }
}

function generateSessionId(): string {
    const dateStr = Date.now().toString(36);
    const randomStr = Math.random().toString(36).substring(2);

    return `${dateStr}${randomStr}`;
}

export default class MoveCalculatorWidget {
    static el;
    static miniEl;
    static version = packageJson.version;
    static sessionId = generateSessionId();

    static mount(
        parentElement: string | HTMLElement,
        parameters: IMoveCalculatorParameters,
        miniCalculatorElement?: string | HTMLElement
    ) {
        const {
            clientId,
            housing,
            offer,
            insights,
            translation,
            debug,
            savingsCalculator,
            timeline,
            existing,
            defaultHousingInterest,
            defaultDownpaymentRate,
            onEvent: clientProvidedOnEvent,
            onEvaluation,
            miniCalculator,
            econansLeadsTracking,
            userHasAcceptedCookies,
        } = parameters;

        const onEvent = async (event: TrackingData) => {
            if (typeof clientProvidedOnEvent === "function") {
                clientProvidedOnEvent(event);
            }

            await econansTracking.init({
                productId: "moving-calculator",
                productVersion: MoveCalculatorWidget.version,
                clientId,
                userHasAcceptedCookies: userHasAcceptedCookies ?? false,
                apiEnvironment:
                    process.env.CUSTOM_ENV === "release"
                        ? "production"
                        : "staging",
                apiKey: process.env.ECONANS_API_KEY || "",
                debug: process.env.NODE_ENV === "development",
            });
            await econansTracking.track(event);
        };

        config.set(
            {
                defaultHousingInterest,
                defaultDownpaymentRate,
                offer,
                insights,
                onEvaluation,
                miniCalculator,
                savingsCalculator,
                timeline,
                existing,
                onEvent,
                econansLeadsTracking,
            },
            { freeze: false, assign: false }
        );

        initTranslation(translation, debug);

        const income = computeHouseholdIncome(housing.estimatedPrice);
        const loan = createProspectHousingLoan(housing.estimatedPrice, income);
        const initialState: IRootState = {
            income,
            prospect: createHousing(housing, [loan]),
            savings: createSavings(housing.estimatedPrice),
        };

        const store = createStore(
            rootReducer,
            initialState,
            composeEnhancers()(applyMiddleware(thunk))
        );

        const element = React.createElement(MoveCalculator);
        const component = React.createElement(Provider, {
            store,
            children: element,
        });

        function doRender() {
            if (MoveCalculatorWidget.el) {
                throw new Error(
                    "MoveCalculatorWidget is already mounted, unmount first"
                );
            }
            const el = document.createElement("div");
            el.setAttribute("class", "cleanslate");

            if (!parentElement)
                throw new Error("Invalid parent element for widget.");

            if (typeof parentElement === "string") {
                document.querySelector(parentElement).appendChild(el);
            } else if (parentElement instanceof HTMLElement) {
                (parentElement as HTMLElement).appendChild(el);
            } else {
                throw new TypeError(
                    "Invalid parent element when initializing widget"
                );
            }

            if (miniCalculatorElement)
                doRenderMiniCalculator(miniCalculatorElement);

            ReactDOM.render(component, el);
            MoveCalculatorWidget.el = el;
        }

        function doRenderMiniCalculator(
            miniCalculatorElement: string | HTMLElement
        ) {
            if (
                !parameters.miniCalculator ||
                !parameters.miniCalculator.onGoToCalculation
            ) {
                console.error(
                    "Mini calculator widget configuration is missing"
                );
                return;
            }

            if (MoveCalculatorWidget.miniEl) {
                console.error(
                    "MiniCalculatorWidget is already mounted, unmount first"
                );
                return;
            }
            const miniEl = document.createElement("div");
            miniEl.setAttribute("class", "cleanslate");
            const miniElement = React.createElement(MiniMoveCalculator);
            const miniComponent = React.createElement(Provider, {
                store,
                children: miniElement,
            });
            if (typeof miniCalculatorElement === "string") {
                const miniParent = document.querySelector(
                    miniCalculatorElement
                );
                if (!miniParent) {
                    console.error("Invalid mini parent element for widget.");
                    return;
                }

                miniParent.appendChild(miniEl);
            } else if (miniCalculatorElement instanceof HTMLElement) {
                (miniCalculatorElement as HTMLElement).appendChild(miniEl);
            }
            ReactDOM.render(miniComponent, miniEl);
            MoveCalculatorWidget.miniEl = miniEl;
        }

        if (
            document.readyState === "complete" ||
            document.readyState === "interactive"
        ) {
            doRender();
        } else {
            window.addEventListener("DOMContentLoaded", () => {
                doRender();
            });
        }
    }

    static unmount() {
        if (!MoveCalculatorWidget.el) {
            throw new Error("MoveCalculatorWidget is not mounted, mount first");
        }
        ReactDOM.unmountComponentAtNode(MoveCalculatorWidget.el);
        MoveCalculatorWidget.el.parentNode.removeChild(MoveCalculatorWidget.el);
        MoveCalculatorWidget.el = null;

        if (MoveCalculatorWidget.miniEl) {
            ReactDOM.unmountComponentAtNode(MoveCalculatorWidget.miniEl);
            MoveCalculatorWidget.miniEl.parentNode.removeChild(
                MoveCalculatorWidget.miniEl
            );
            MoveCalculatorWidget.miniEl = null;
        }
    }
}
