import { minInstallment } from "../functions/installment";
import { IHousing } from "../models/housing";
import { roundWithPrecision } from "../utils/format";
import { forecastLoan } from "../functions/loans";
import { MONTHS_IN_YEAR } from "../defaults";
import { calculateLoanToValue } from "../functions/housing";

export function getLoanToValue(housing: IHousing): number {
    const ratio = calculateLoanToValue(housing);

    return roundWithPrecision(ratio, 4);
}

export function getFutureInstallment(housing: IHousing, years: number): number {
    let installment = 0;

    for (let i = 0; i < years; i++) {
        const loans = housing.loans.map((loan) => forecastLoan(loan, i));
        const totalInstallmentPerMonth = loans.reduce(
            (n, { installment }) => n + installment,
            0
        );
        const totalInstallmentPerYear =
            totalInstallmentPerMonth * MONTHS_IN_YEAR;

        installment = installment + totalInstallmentPerYear;
    }

    return installment;
}

export function getMinInstallment(income: number, housing: IHousing): number {
    return minInstallment(
        housing.price,
        getTotalLoanAmount(housing),
        income || 0
    );
}

export function getInstallmentRate(housing: IHousing): number {
    const totalAmountLoans = getTotalLoanAmount(housing);
    const totalInstallmentPerMonth = getTotalLoanInstallment(housing);
    const totalInstallmentPerYear = totalInstallmentPerMonth * MONTHS_IN_YEAR;
    if (totalAmountLoans === 0) return 0;

    const ratio = totalInstallmentPerYear / totalAmountLoans;
    if (ratio === 0) return 0;

    return roundWithPrecision(ratio, 4);
}

export function getTotalLoanAmount(housing: IHousing): number {
    return housing.loans !== null
        ? housing.loans.reduce((n, { amount }) => n + amount, 0)
        : 0;
}

export function getTotalLoanInstallment(housing: IHousing): number {
    return housing.loans !== null
        ? housing.loans.reduce((n, { installment }) => n + installment, 0)
        : 0;
}
