import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    IRootState,
    setExistingCalculateCapitalGainTax,
    setExistingOriginalPrice,
    setExistingRenovationCost,
} from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import Switch from "../../form_controls/switch_component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import {
    PRICE_STEP,
    RENOVATION_COST_MAX,
    RENOVATION_COST_STEP,
} from "../../../defaults";
import { getOriginalPriceMax } from "../../../selectors/housings";

export default function CapitalGainTaxField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const {
        calculateCapitalGainTax,
        renovationCost,
        originalPrice,
        maxPrice,
        type,
    } = useSelector((state: IRootState) => {
        return {
            calculateCapitalGainTax: state.existing.calculateCapitalGainTax,
            renovationCost: state.existing.renovationCost,
            originalPrice: state.existing.originalPrice,
            maxPrice: getOriginalPriceMax(state.existing),
            type: state.prospect.type,
        };
    });

    function onCalculateChanged() {
        dispatch(setExistingCalculateCapitalGainTax(!calculateCapitalGainTax));
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Capital_Gain_Tax,
                housingType: type,
                value: (!calculateCapitalGainTax).toString(),
            })
        );
    }

    function onPriceChange(value: number) {
        dispatch(setExistingOriginalPrice(value));
    }

    function onPriceSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Original_Price,
                value: value.toString(),
                housingType: type,
            })
        );
    }

    function onRenovationCostChange(value: number) {
        dispatch(setExistingRenovationCost(value));
    }

    function onRenovationCostSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Renovation_Cost,
                value: value.toString(),
                housingType: type,
            })
        );
    }

    return (
        <>
            <Switch
                label={t("existing:calculate-capital-gain")}
                hint={t("hint:calculate-capital-gain")}
                checked={calculateCapitalGainTax}
                onChange={onCalculateChanged}
                name="existing[calculateCapitalGainTax]"
            />
            {calculateCapitalGainTax && (
                <>
                    <Number
                        label={t("existing:original-price")}
                        unit="kr"
                        ariaLabel={t("common:currency")}
                        value={originalPrice}
                        max={maxPrice}
                        step={PRICE_STEP}
                        name="existing[originalPrice]"
                        onChange={onPriceChange}
                        onValueSet={onPriceSet}
                    />
                    <Number
                        label={t("existing:renovation-cost")}
                        unit="kr"
                        ariaLabel={t("common:currency")}
                        hint={t("hint:renovation-cost")}
                        value={renovationCost}
                        max={RENOVATION_COST_MAX}
                        step={RENOVATION_COST_STEP}
                        name="existing[renovationCost]"
                        onChange={onRenovationCostChange}
                        onValueSet={onRenovationCostSet}
                    />
                </>
            )}
        </>
    );
}
