import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";

import sv from "../locales/sv";

const defaultLngKey = "sv";
const customLngKey = "custom";

const resources = {
    sv,
};

export interface ITranslation {
    [key: string]: string;
}

export default function initTranslation(
    translations: ITranslation,
    debugMode: boolean
) {
    i18n.use(initReactI18next).init({
        resources,
        fallbackLng: defaultLngKey,
        lng: debugMode ? "cimode" : defaultLngKey,
        saveMissing: true,
        debug: debugMode,
        appendNamespaceToCIMode: debugMode,
        missingKeyHandler: (lng, ns, key) => {
            if (
                lng.includes(defaultLngKey) &&
                process.env.NODE_ENV !== "production"
            ) {
                throw new Error(
                    `Default language: "${defaultLngKey}" is missing translation for key "${ns}:${key}"`
                );
            }
        },
    });

    if (debugMode) {
        return;
    }

    overload(translations);
}

function extractNamespaces(translation: ITranslation) {
    const customResources: ResourceLanguage = {};
    for (const key of Object.keys(translation)) {
        const res = key.split(":");
        if (res == null || res.length !== 2) {
            throw new Error(
                `Custom language key "${key}" has incorrect format`
            );
        }

        const [namespace, tk] = res;
        if (!customResources[namespace]) {
            customResources[namespace] = {};
        }

        customResources[namespace][tk] = translation[key];
    }

    return customResources;
}

function overload(translation: ITranslation) {
    if (!translation) {
        return;
    }

    const customResources = extractNamespaces(translation);
    for (const customResource of Object.keys(customResources)) {
        i18n.addResources(
            customLngKey,
            customResource,
            customResources[customResource]
        );
    }

    i18n.changeLanguage(customLngKey);
}
